<template>
    <section class="row py-5 bg-white">
        <div class="row mx-2">
            <div class="col">
                <button v-if="selected !== 0" class="btn tw-button tw-default-bg-color text-white float-end ms-1 position-relative" title="Delete Selected Events" @click="bulkDeleteEvents">
                    <i class="fas fa-trash"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"
                        ><small>{{ selected }}</small></span
                    >
                </button>
                <button class="btn tw-button tw-default-bg-color text-white float-end ms-1" title="Reset Filters" @click="resetFilters">
                    <i class="fas fa-undo"></i>
                </button>
                <router-link to="/events" class="btn tw-button tw-default-bg-color text-white float-end" title="Back to Events">
                    <i class="fas fa-arrow-circle-left"></i>
                </router-link>
            </div>
        </div>
        <div class="row mx-2 mb-5">
            <div class="col">
                <div class="pt-2">
                    <select id="type-filter" class="form-select rounded-1 text-muted" v-model="filters.type">
                        <option value="">Type</option>
                        <option value="single">Single</option>
                        <option value="recurrence">Recurrent</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <div class="pt-2">
                    <select id="status-filter" class="form-select rounded-1 text-muted" v-model="filters.status">
                        <option value="">Status</option>
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="deleted">Deleted</option>
                    </select>
                </div>
            </div>
            <div class="col-4 text-center">
                <div class="row d-inline-flex justify-content-center pt-2">
                    <div class="col"><Datepicker class="rounded-pill w-100" v-model="filters.date_start" :format="format" :clearable="true" placeholder="Start Date" @change="setStartDate" /></div>
                    <div class="col"><Datepicker class="roundde-pill w-100" v-model="filters.date_end" :format="format" :clearable="true" placeholder="End Date" @change="setEndDate" /></div>
                </div>
            </div>
            <div class="col">
                <div class="pt-2">
                    <input type="search" class="form-control rounded-1" placeholder="Search..." v-model="filters.q" />
                </div>
            </div>
        </div>
        <div class="row mx-2">
            <div class="table-responsive">
                <table class="tw-table table text-nowrap">
                    <thead>
                        <tr>
                            <th class="tw-checkbox"><input id="events-bulk-select" type="checkbox" @click="selectAll" :checked="bulkCheck" /><label for="events-bulk-select"></label></th>
                            <th>Name</th>
                            <th>Status</th>
                            <th @click="sortTable('starts_at', 'desc')" class="hover cursor-pointer">
                                Start Date
                                <span v-if="sorting.orderBy === 'starts_at'">
                                    <span v-if="sorting.order === 'asc'"><i class="fas fa-angle-up"></i></span>
                                    <span v-else><i class="fas fa-angle-down"></i></span>
                                </span>
                            </th>
                            <th @click="sortTable('ends_at', 'desc')" class="hover cursor-pointer">
                                End Date
                                <span v-if="sorting.orderBy === 'ends_at'">
                                    <span v-if="sorting.order === 'asc'"><i class="fas fa-angle-up"></i></span>
                                    <span v-else><i class="fas fa-angle-down"></i></span>
                                </span>
                            </th>
                            <th>Duration</th>
                            <th>Recurring</th>
                            <th>Category</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(event, index) in events" :key="`event-list-${event}`" class="text-muted">
                            <td class="tw-checkbox"><input :id="'event' + event?.uuid ?? event?.id" type="checkbox" @click="countSelected(index, $event)" value="1" :checked="event.checked" /><label :for="'event' + event?.uuid ?? event?.id"></label></td>

                            <td>
                                <a :href="`https://app.heygov.com/${domain}/events-embed/${event?.uuid ?? event?.id}`" target="_blank">{{ event.name }}</a>
                            </td>
                            <td class="text-center">
                                <span class="badge" :class="eventStatuses[event.status].class">{{ eventStatuses[event.status].name }}</span>
                            </td>
                            <td>
                                {{ new Date(event.starts_at).toLocaleDateString("en-US", { timeZone: jurisdiction.timezone, month: "short", day: "numeric", year: "numeric" }) }} @
                                {{ new Date(event.starts_at).toLocaleTimeString("en-US", { timeZone: jurisdiction.timezone, timeStyle: "short" }) }}
                            </td>
                            <td>
                                {{ new Date(event.ends_at).toLocaleDateString("en-US", { timeZone: jurisdiction.timezone, month: "short", day: "numeric", year: "numeric" }) }} @
                                {{ new Date(event.ends_at).toLocaleTimeString("en-US", { timeZone: jurisdiction.timezone, timeStyle: "short" }) }}
                            </td>
                            <td>{{ differenceInHours(event) }}</td>
                            <td>
                                <span v-if="event.type === 'single'"><small>No</small></span>
                                <span v-else class="text-muted fs-6"><small>Yes</small> </span>
                            </td>
                            <td>
                                <span v-if="event.categories && event.categories.length > 0"
                                    ><span v-for="c in event.categories" :key="`category-${c}`" class="badge bg-light text-dark me-2">{{ findCategoryValue(c, "name") }}</span></span
                                >
                                <span v-else><small class="text-muted bg-light">No Event Category</small></span>
                            </td>
                            <td class="column-actions">
                                <a v-if="event.status !== 'deleted'" href="" @click.prevent.stop="openCreateEventModal(event)" class="d-flex align-items-center"
                                    ><span class="fa-stack"><i class="tw-light-red-color fa fa-circle fa-stack-2x"></i><i title="Update single event" class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i></span
                                ></a>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr v-if="events.length > 0">
                            <td colspan="4">
                                Rows per page
                                <select v-model="pag.perPage" class="border">
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="50">50</option>
                                </select>
                            </td>
                            <td colspan="5" class="tw-table-pagination text-end">
                                <Pagination :totalItems="pag.total" :currentPage="pag.page" :perPage="pag.perPage" @pageChanged="setPage" :goButton="false" styled="centered" borderActiveColor="" borderTextActiveColor="text-white" />
                            </td>
                        </tr>
                        <tr v-else>
                            <td colspan="9" class="text-center">No events, try reseting the filters</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <CreateEventModal ref="CreateEventModal" :editing="editing" :editingEventId="editingEventId" :eventWithUuid="eventWithUuid" :intervalLabels="intervalLabels" @eventSaved="handleEventSaved" @modalHidden="handleModalHidden" />
    </section>
</template>

<script>
import heyGovMixin from "@/mixins/heyGovMixin";
import helperMixin from "@/mixins/helperMixin";
import siteMixin from "@/mixins/siteMixin";
import userMixin from "@/mixins/userMixin";
import Pagination from "@/components/pagination/Pagination";
import CreateEventModal from "@/components/modals/CreateEventModal";

export default {
    name: "EventsList",
    components: { Pagination, CreateEventModal },
    data: () => ({
        events: [],
        categories: [],
        jurisdiction: null,
        states: {
            events: "loading",
            categories: "loading",
        },
        format: "MMM dd Y",
        filters: {
            date_start: "",
            date_end: "",
            type: "",
            status: "public",
            q: "",
        },
        pag: {
            perPage: parseInt(localStorage.getItem("events-per-page")) || 10,
            total: "",
            page: 1,
        },
        sorting: {
            orderBy: localStorage.getItem("events-order-by") || "starts_at",
            order: localStorage.getItem("events-orded") || "desc",
        },
        eventStatuses: {
            public: {
                name: "Public",
                class: "bg-success text-white",
            },
            private: {
                name: "Private",
                class: "bg-info text-dark",
            },
            deleted: {
                name: "Deleted",
                class: "bg-danger text-white",
            },
        },
        selected: 0,
        bulkCheck: false,
        editing: false,
        editingEventId: null,
        eventWithUuid: null,
        intervalLabels: {},
        wpBasicHeaders: null,
    }),
    mounted() {
        this.setIntervalLabels();
        this.getHeygovJurisdiction();
        this.loadEvents();
        this.loadCategories();
        let user = this.getCurrentUser();
        this.wpBasicHeaders = { Authorization: "Basic " + user.auth, "Content-Type": "application/json" };
    },

    methods: {
        loadEvents() {
            let params = {
                ...this.sorting,
                ...this.filters,
                page: this.pag.page,
                limit: this.pag.perPage,
            };
            this.axios.get(`${this.apiRoute}/${this.domain}/events?expand=parent`, { params }).then((data) => {
                this.events = data.data;
                this.pag.total = parseInt(data.headers["x-total"]);
                this.states.events = "loaded";
            });
        },
        loadCategories() {
            this.axios.get(`${this.apiRoute}/${this.domain}/events/categories`).then((data) => {
                this.categories = data.data;
                this.states.categories = "loaded";
            });
        },
        async getHeygovJurisdiction() {
            this.jurisdiction = await this.heyGovJurisdiction();
        },
        setPage(n) {
            this.pag.page = n;
            return this.pag.page;
        },
        setStartDate(date) {
            this.filters.date_start = this.moment(date).format("YYYY-MM-DD");
            return this.filters.date_start;
        },
        setEndDate(date) {
            this.filters.date_end = this.moment(date).format("YYYY-MM-DD");
            return this.filters.date_end;
        },
        resetFilters() {
            this.filters = {
                date_start: "",
                date_end: "",
                type: "",
                status: "public",
                q: "",
            };
            this.pag.page = 1;
            this.selected = 0;
        },
        countSelected(index, event) {
            if (event.target.checked) {
                this.events[index].selected = true;
            } else {
                this.events[index].selected = false;
            }

            this.selected = this.events.filter((e) => e.selected == true).length || 0;
        },
        bulkDeleteEvents() {
            this.$swal({
                title: "Are you sure?",
                showCancelButton: true,
                icon: "warning",
                text: "Are you sure you want to delete all selected events?",
                confirmButtonText: "Yes, delete!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await Promise.all(
                        this.events.map(async (event) => {
                            if (event.selected) {
                                let res = await this.axios.delete(`${this.apiRoute}/${this.domain}/events/${event?.uuid ?? event?.id}?apiKey=${this.apiKey}`);
                                if (res && res.status === 202) {
                                    this.loadEvents();
                                    if (event.type === "recurrent") {
                                        this.events.filter((e) => e.source_event === event?.uuid ?? event?.id);
                                    }
                                    this.selected = 0;
                                    this.bulkCheck = false;
                                }
                            }
                        })
                    );
                    this.$swal("Deleted!", "Events have been deleted!", "success");
                    if (this.siteType === "wordpress") {
                        this.heyGovEventsClearCache();
                    }
                }
            });
        },
        selectAll() {
            if (this.bulkCheck === false) {
                this.bulkCheck = true;
                this.events.map((e) => {
                    e.selected = true;
                    e.checked = true;
                });
                this.selected = this.events.filter((e) => e.selected == true).length;
            } else {
                this.bulkCheck = false;
                this.events.map((e) => {
                    e.selected = false;
                    e.checked = false;
                });
                this.selected = 0;
            }
        },
        sortTable(orderBy, defaultOrder) {
            if (this.sorting.orderBy === orderBy) {
                this.sorting.order = this.sorting.order === "asc" ? "desc" : "asc";
            } else {
                this.sorting.order = defaultOrder;
            }

            this.sorting.orderBy = orderBy;
        },
        openCreateEventModal(event) {
            if (event.id) {
                this.editing = true;
                this.editingEventId = event.id;
            }
            if (this.hasHeyGovEvents) {
                this.eventWithUuid = event;
            }

            this.$refs.CreateEventModal.showModal(true);
        },
        handleModalHidden() {
            this.editing = false;
            this.editingEventId = null;
            if (this.hasHeyGovEvents) {
                this.eventWithUuid = null;
            }
        },
        handleEventSaved() {
            this.$swal("Event saved!", "Event saved successfully!", "success").then(() => {
                this.loadEvents();
            });
        },
        setIntervalLabels() {
            this.intervalLabels = this.getIntervalLabels();
        },
        findCategoryValue(cat, value) {
            let property;
            if (this.categories.length) {
                for (let category of this.categories) {
                    if (category.id === cat || category.uuid === cat) {
                        property = category[value];
                    }
                }
            }
            return property;
        },
        differenceInHours(event) {
            let start = this.moment(event.starts_at);
            let end = this.moment(event.ends_at);
            let diff;
            if (end.isAfter(start)) {
                diff = `${end.diff(start, "hours")}h`;
                if (diff === "0h") {
                    diff = `${end.diff(start, "minutes")}m`;
                }
            } else {
                diff = "Negative duration!!!";
            }
            return diff;
        },
        heyGovEventsClearCache() {
            this.axios.get(this.siteUrl + `/wp-json/tw/v1/events-transient-wprocketcache-clear`, {
                headers: this.wpBasicHeaders,
            });
        },
    },
    computed: {
        siteType() {
            return this.getSiteType();
        },
        siteUrl() {
            return this.getSiteUrl();
        },
    },
    mixins: [heyGovMixin, helperMixin, siteMixin, userMixin],
    watch: {
        filters: {
            deep: true,
            handler() {
                this.events = [];
                this.loadEvents();
            },
        },
        sorting: {
            deep: true,
            handler() {
                this.events = [];
                this.loadEvents();
                localStorage.setItem("events-order-by", this.sorting.orderBy);
                localStorage.setItem("events-order", this.sorting.order);
            },
        },
        "pag.page"() {
            this.events = [];
            this.selected = 0;
            this.bulkCheck = false;
            this.loadEvents();
        },
        "pag.perPage"() {
            this.events = [];
            this.pag.page = 1;
            this.selected = 0;
            this.bulkCheck = false;
            this.loadEvents();
            localStorage.setItem("events-per-page", this.pag.perPage);
        },
    },
};
</script>
<style>
.tw-rounded {
    border-radius: 10px !important;
}
</style>
